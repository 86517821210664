@media (min-width: 1801px) {
    .hands {
        margin-top: 150px;
    }

    .section-heading {
        font-size: 55px;
    }
}

@media (max-width: 1500px) {
    .hands {
        margin-top: 180px;
    }

    .automate-your-life {
        font-size: 200px;
        margin-top: 20px;
    }

    .play-button {
        margin-top: 320px;
    }

    /*.explainer-video-play-button {*/
    /*    margin-top: 320px;*/
    /*}*/
    .banner-video-wrapper {
        height: 75vh;
    }

    .home-page-text {
        top: 790px;
    }

    .switch-buttons-wrapper {
        margin-top: 710px;
    }

    .section-content {
        font-size: 15px;
    }
}

@media (max-width: 1300px) {
    .title {
        margin-left: 25px;
        font-size: 30px;
        letter-spacing: 25px;
    }

    .hands {
        margin-top: 165px;
    }

    .play-button {
        width: 80px;
        height: 80px;
        margin-top: 275px;
    }

    .explainer-video-play-button {
        width: 80px;
        height: 80px;
        /*margin-top: 275px;*/
    }

    .automate-your-life {
        font-size: 200px;
        margin-top: 20px;
    }

    .triangle {
        border-width: 8px 0 8px 14px;
    }

    .automate-your-life {
        font-size: 170px;
        margin-top: 35px;
    }

    .banner-video-wrapper {
        height: 65vh;
    }

    .home-page-text {
        top: 700px;
    }

    .switch-buttons-wrapper {
        margin-top: 620px;
    }

    .page-heading {
        font-size: 120px;
        margin-top: -15px;
        margin-bottom: -10px;
    }

    /*.explainer-video-play-button {*/
    /*    margin-top: 25%;*/
    /*}*/
}

@media (max-width: 1200px) {
    .banner-video-wrapper {
        height: 60vh;
    }

    .home-page-text {
        top: 655px;
    }

    .switch-buttons-wrapper {
        margin-top: 575px;
    }

    .enclosed {
        padding: 3rem;
    }

    .section-heading {
        font-size: 30px;
    }

    .congress-party {
        font-size: 20px;
    }

    .section-content {
        font-size: 14px;
    }

    .admin-user-subheading {
        font-size: 22px;
    }

    .success-or-error {
        top: -.5rem;
    }

    .success-or-error-analyze {
        top: -12rem;
    }

    .success-or-error-notifications {
        top: -1rem;
    }

    .success-or-error-login {
        top: -5rem;
    }

    .rf-full-logo {
        width: 250px;
    }

    /*.explainer-video-play-button {*/
    /*    margin-top: 25%;*/
    /*}*/
    /*.indicators-table {*/
    /*    width: 70%;*/
    /*}*/
}

@media (max-width: 990px) {
    .title {
        margin-left: 20px;
        font-size: 25px;
        letter-spacing: 20px;
    }

    .hands {
        margin-top: 150px;
    }

    .play-button {
        width: 70px;
        height: 70px;
        margin-top: 235px;
    }

    .explainer-video-play-button {
        width: 70px;
        height: 70px;
        /*margin-top: 235px;*/
    }

    .triangle {
        border-width: 7px 0 7px 12px;
    }

    .automate-your-life {
        font-size: 130px;
        margin-top: 40px;
    }

    .banner-video-wrapper {
        height: 50vh;
    }

    .home-page-text {
        top: 565px;
    }

    .switch-buttons-wrapper {
        margin-top: 485px;
    }

    .enclosed {
        padding: 2rem;
    }

    .page-heading {
        font-size: 80px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .small-heading {
        font-size: 80px;
        line-height: 1;
        margin-top: 18px;
        margin-bottom: 25px;
    }

    /*.admin-dashboard-entries {*/
    /*    width: 38%;*/
    /*}*/
    .admin-dashboard-content {
        /*width: 60%;*/
    }

    .section-subheading {
        font-size: 22px;
    }

    .congress-party {
        font-size: 18px;
    }

    .analyze-price {
        font-size: 22px;
    }

    .create-subscription-input {
        width: 85%;
        margin-right: 0;
    }

    .nav-link {
        /*display: none;*/
        opacity: 0;
        visibility: hidden;
    }

    .nav-link-hidden {
        display: none;
    }

    .hamburger {
        /*visibility: visible;*/
        display: block;
    }

    .slide-menu {
        display: block;
    }

    .full-logo-wrapper {
        width: 100%;
        margin: auto auto 30px;
    }

    .full-logo {
        width: 60%;
    }

    .rf-full-logo {
        width: 300px;
    }

    .quick-links {
        width: 100%;
        margin: auto;
    }

    .learn-more-wrapper {
        margin-bottom: 1rem;
    }

    .subscription-subheading {
        font-size: 40px;
    }

    .payment-link-subheading {
        font-size: 40px;
    }

    .user-subheading {
        font-size: 40px;
    }

    .success-or-error {
        top: -.35rem;
    }

    .success-or-error-analyze {
        /*top: -.35rem;*/
    }

    .success-or-error-notifications {
        top: -.7rem;
    }

    .success-or-error-congress {
        top: -8.3rem;
    }

    .success-or-error-login {
        top: -3.5rem;
    }

    /*.explainer-video-play-button {*/
    /*    margin-top: 25%;*/
    /*}*/
    .indicators-table {
        width: 100%;
    }

    .chart-container {
        height: 300px;
    }

}

@media (max-width: 767px) {
    .title {
        margin-left: 15px;
        font-size: 20px;
        letter-spacing: 15px;
    }

    .hands {
        margin-top: 130px;
    }

    .play-button {
        width: 60px;
        height: 60px;
        margin-top: 195px;
    }

    .explainer-video-play-button {
        width: 60px;
        height: 60px;
        /*margin-top: 25%;*/
    }

    .triangle {
        border-width: 6px 0 6px 10px;
    }

    .automate-your-life {
        font-size: 90px;
        margin-top: 60px;
    }

    .banner-video-wrapper {
        height: 38vh;
    }

    .home-page-text {
        top: 455px;
    }

    .switch-buttons-wrapper {
        margin-top: 375px;
    }

    .section {
        margin-top: 2rem;
    }

    .enclosed {
        padding: 2rem 1rem;
    }

    .half-section {
        width: 100%
    }

    .section-heading {
        font-size: 22px;
        text-align: center;
    }

    .congress-party {
        font-size: 16px;
        margin-top: -5px;
        margin-bottom: 10px;
    }

    .section-subheading {
        font-size: 18px;
    }

    .analyze-price {
        font-size: 18px;
        margin-top: -.5rem;
    }

    .section-heading-enclosed {
        margin-top: 1.5rem
    }

    .learn-more-wrapper {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .learn-more {
        width: auto;
        padding: 13px 50px;
        font-size: 14px;
    }

    .send-wrapper {
        text-align: center;
        margin-bottom: -2rem;
    }

    .send {
        width: auto;
        padding: 13px 50px;
        font-size: 14px;
        margin: auto;
    }

    .page-heading {
        font-size: 40px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mobile-negative-margin-top-2 {
        margin-top: -2rem;
    }

    .mobile-negative-margin-top-1-5 {
        margin-top: -1.5rem;
    }

    .mobile-margin-top-0 {
        margin-top: 0;
    }

    .mobile-margin-bottom-0 {
        margin-bottom: 0;
    }

    .small-heading {
        font-size: 40px;
        line-height: 1;
        margin-top: 18px;
        margin-bottom: 25px;
    }

    .contact-form-form {
        margin-top: -3rem;
    }

    .admin-dashboard-entries {
        width: 100%;
        margin-right: 0;
    }

    .admin-dashboard-entry {
        text-align: center;
        width: 50%;
        display: inline-block;
        padding-left: 0;
    }

    /*.analyze-dashboard-entry {*/
    /*    text-align: center;*/
    /*    width: 33%;*/
    /*    display: inline-block;*/
    /*    padding-left: 0;*/
    /*}*/
    .reddit-dashboard-entries {
        width: 100%;
        margin-right: 0;
    }

    .reddit-dashboard-entry {
        text-align: center;
        height: 30px;
        width: 50%;
        max-width: 220px;
        display: inline-block;
        padding: 0;
        font-size: 14px;
        align-content: center;
    }

    .analyze-dashboard-entry {
        text-align: center;
        height: 40px;
        display: inline-block;
        padding: 0;
        font-size: 14px;
        align-content: center;
    }

    .admin-dashboard-content {
        width: 100%;
        text-align: left;
    }

    .reddit-dashboard-content {
        width: 100%;
        text-align: left;
    }

    .admin-subheading {
        /*margin-top: 1rem;*/
        margin-bottom: .5rem;
        /*margin-left: 15px;*/
    }

    .dashboard-number-of {
        height: max-content;
        font-size: 20px;
        text-align: center;
    }

    .footer-links {
        width: 33%;
    }

    .footer-links-social {
        width: 50%;
        margin-top: 20px;
        margin-bottom: -10px;
    }

    .copyright {
        font-size: 12px;
    }

    .dmca {
        width: 80px;
    }

    .subscription-subheading {
        font-size: 30px;
    }

    .subscription-info {
        width: 80%;
        margin: 20px;
    }

    .subscription-info-heading {
        font-size: 18px;
    }

    .subscription-info-content {
        font-size: 16px;
    }

    .payment-link-subheading {
        font-size: 30px;
    }

    .payment-link-info {
        width: 80%;
        margin: 20px;
    }

    .payment-link-info-heading {
        font-size: 18px;
    }

    .payment-link-info-content {
        font-size: 16px;
    }

    .user-subheading {
        font-size: 30px;
    }

    .user-info {
        width: 80%;
        margin: 20px;
    }

    .user-info-heading {
        font-size: 18px;
    }

    .user-info-content {
        font-size: 16px;
    }

    .admin-user-subheading {
        font-size: 18px;
    }

    .success-or-error-password-restriction {
        font-size: 14px;
    }

    .success-or-error-analyze {
        top: -10rem;
    }

    .success-or-error-notifications {
        /*top: .6rem;*/
    }

    .success-or-error-login {
        top: -3.5rem;
    }

    .indicators-tr {
        font-size: 14px;
    }

    .comment-subreddit-name {
        margin-top: 2px;
        font-size: 16px;
    }

    .reddit-comment-row {
        font-size: 14px;
    }

    .dashboard-th {
        font-size: 14px;
    }

    .dashboard-td {
        font-size: 14px;
    }

    .congress-td {
        /*font-size: 14px;*/
        overflow-x: visible;
    }

    .congress-transaction-type {
        text-align: center;
    }

    .congress-amount {
        font-size: 13px;
    }

    .notification-name {
        margin-top: 2px;
        font-size: 16px;
    }

    .watchlist-remove-button {
        height: 23px;
    }

    .have-account {
        font-size: 14px;
    }

    .no-account {
        font-size: 14px;
    }

    .user-account-link {
        font-size: 14px;
    }

    .chart-container {
        height: 300px;
    }

    .error-loading {
        font-size: 14px;
    }

    .terms-heading {
        font-size: 22px;
    }
    .terms-paragraph {
        font-size: 14px;
    }

}

@media (max-width: 550px) {
    .title {
        margin-left: 10px;
        font-size: 15px;
        letter-spacing: 10px;
    }

    .hands {
        margin-top: 110px;
    }

    .play-button {
        width: 50px;
        height: 50px;
        margin-top: 155px;
    }

    .explainer-video-play-button {
        width: 50px;
        height: 50px;
        /*margin-top: 25%;*/
    }

    .triangle {
        border-width: 5px 0 5px 8px;
    }

    .automate-your-life {
        font-size: 60px;
        margin-top: 70px;
    }

    .banner-video-wrapper {
        height: 30vh;
    }

    .home-page-text {
        top: 380px;
        position: absolute;
    }

    .switch-buttons-wrapper {
        margin-top: 300px;
    }

    .page-heading {
        font-size: 35px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .small-heading {
        font-size: 35px;
        line-height: 1;
        margin-top: 18px;
        margin-bottom: 25px;
    }

    .dmca {
        bottom: 35px;
    }

    .reddit-switch-button {
        width: 40px;
    }

    .filter-dropdown-notification {
        font-size: 14px;
        line-height: 20px;
        height: 25px;
        width: 102px;
    }

    .filter-number {
        font-size: 16px;
        height: 25px;
        width: 102px;
    }

    .filter-number::placeholder {
        line-height: 20px;
        font-size: 14px;
        height: 25px;
        width: 105px;
    }

    .notify-of {
        font-size: 14px;
    }

    .notification-save {
        font-size: 14px;
    }

    .nothing-here {
        font-size: 14px;
    }

    .conditions-symbol {
        font-size: 14px;
        padding-left: .5rem;
    }

    .ticker-input-notification {
        font-size: 16px;
        line-height: 20px;
        height: 25px;
        width: 75px;
    }

    .congress-td {
        font-size: 10px;
        overflow-y: scroll;
    }

    .congress-amount {
        font-size: 9px;
    }

    .mention-link {
        margin-left: 1rem;
    }

    .dashboard-td {
        padding-right: 5px;
    }

    .rf-full-logo {
        width: 250px;
    }

    .reddit-dashboard-entry {
        max-width: 180px;
    }

    .success-or-error-password-restriction {
        font-size: 10px;
    }

    .chart-container {
        height: 250px;
    }

    .footer-link {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
    }

    .nav-link:hover,
    .user-icon:hover,
    .click-here:hover,
    .dropdown-entry:hover,
    .footer-link:hover,
    .slide-link-a:hover,
    .dashboard-link:hover,
    .chart-switch-button:hover,
    .user-account-link:hover,
    .reddit-switch-button:hover {
        color: inherit;
    }

    .notification-name {
        width: 202px
    }

    .suggestions-notification {
        width: 75px;
        margin-left: 3.5px;
    }

    .max-height-600-400 {
        max-height: 400px;
    }

    .terms-heading {
        font-size: 20px;
    }

    .admin-user-td-text {
        font-size: 10px;
    }


}