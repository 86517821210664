::-webkit-scrollbar {
    display: none;
}

::selection {
    background-color: #b619ff; /* Background color when text is highlighted */
}

/* For Firefox, which requires the ::-moz-selection pseudo-element */
::-moz-selection {
    background-color: #b619ff; /* Background color when text is highlighted */
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

.slide-in-left {
    animation: slideInFromLeft 1s ease;
}

@keyframes slideOutLeft {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.slide-out-left {
    animation: slideOutLeft .8s ease;
}


@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.slide-in-right {
    animation: slideInFromRight 1s ease;
}

@keyframes slideOutRight {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

.slide-out-right {
    animation: slideOutRight .8s ease;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgb(182 25 255 / 70%);
    }

    70% {
        box-shadow: 0 0 0 20px rgb(174 0 255 / 0%);
    }
    100% {
        box-shadow: 0 0 0 0 rgb(174 0 255 / 0%);
    }
}

@keyframes slideInFromTop {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.slide-in-top {
    animation: slideInFromTop 1s ease;
}

@keyframes scaleUp {
    from {
        transform: scale(.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.scale-up {
    animation: scaleUp .6s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn .5s;
}

.fade-in-long {
    animation: fadeIn 1s;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fade-out {
    animation: fadeOut .5s;
}


html, body {
    background-color: #000416;
    color: #fff;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    /*height: 100%;*/
    margin: 0;
    padding: 0;
}

button {
    padding: 0;
}

button:focus, button:active,
select:focus, select:active,
input:focus, input:active,
textarea:focus, textarea:active {
    outline: none; /* Removes the default outline */
    box-shadow: none; /* Removes any default box shadow */
}

a, a:after, a:hover {
    /*text-decoration: none;*/
    color: #fff;
}

select {
    cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.left {
    text-align: left;
}

.center {
    text-align: center;
}

.bold {
    font-weight: bold;
}

.bright {
    color: #fff;
}

.loading {
    text-align: center;
    font-size: 26px;
    margin: auto;
}

.loading-text {
    color: white;
}

.loading-dots {
    display: inline-flex;
    justify-content: center;
    position: absolute;
    /*margin: 13px 3px;*/
    margin: 23px 3px;
}

.loading-dots > div {
    width: 5px;
    height: 5px;
    margin: 0 1px;
    border-radius: 50%;
    background-color: white;
    opacity: 1;
    animation: loading-dots 0.6s infinite alternate;
}

@keyframes loading-dots {
    to {
        opacity: 0.1;
    }
}

.page {
    background-color: #000416;
    color: #fff;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}

.content {
    /*text-align: center;*/
    /*margin: auto;*/
}

.main-header {

}

.dropdown {
    transition: all 0.5s ease-In-out;
    background-color: #000416;
    /*background-color: red;*/
    width: 100px;
    position: absolute;
    right: -40px;
    /*box-shadow: 15px 30px 40px 5px #b619ff;*/
    box-shadow: 0 10px 30px 0 #610063;
    overflow-y: hidden;
    padding-top: 5px;
    border-radius: 7px;
    z-index: 1;
}

.dropdown-entry {
    text-align: center;
    margin-top: 5px;
    color: #cfcfcf;
    font-size: 17px;
    /*margin-right: 10px;*/
    transition: all 0.4s ease-In-out;
    cursor: pointer;
    /*width: max-content;*/
    display: block;
}

.dropdown-entry:hover {
    color: #b619ff;
}

.page-heading {
    font-size: 190px;
    font-weight: bold;
    margin-top: -40px;
    margin-bottom: -25px;
}

.main-footer {
    position: relative;
}

.footer-content {
    margin: auto auto 4rem;
    width: 90%;
}

.full-logo-wrapper {
    width: 25%;
    margin-right: 10%;
}

.full-logo {
    width: 100%;
    cursor: pointer;
}

.rf-full-logo {
    width: 300px;
    /*height: 80%;*/
    cursor: pointer;
}

.quick-links {
    width: 65%;
    top: 0;
}

.footer-links {
    width: 25%;
    text-align: left;
}

.footer-link-heading {
    line-height: 40px;
    font-weight: bold;
}

.footer-link {
    display: block;
    font-size: 17px;
    color: #fff;
    line-height: 30px;
    transition: all 0.4s ease-In-out;
    width: max-content;
    /*width: max-content;*/
}

.footer-link:hover {
    color: #b619ff;
    text-decoration: none;
}

.social-icon-wrapper {
    margin-right: 10%;

}

.social-icon {
    width: 15%;
    margin-top: 5px;
    /*line-height: 30px;*/
    /*margin-right: 10%;*/
}

.copyright {
    font-size: 14px;
    line-height: 2.14;
    color: #cfcfcf;
    position: relative;
}

.dmca {
    position: absolute;
    bottom: 5px;
    left: 5%;
}

.small-logo {
    max-height: 35px;
    position: absolute;
    left: 26px;
    top: 22px;
    cursor: pointer;
}

.rf-small-logo {
    max-height: 71px;
    position: absolute;
    left: -7px;
    top: 4px;
    cursor: pointer;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-logo {
    max-height: 200px;
    /*background: red;*/
    animation: spin 1s linear infinite;
}

.rf-loading-logo {
    margin: auto;
    /*right: 50%;*/
    height: 300px;
    top: -50px;

    /*background: blue;*/
    position: absolute;
    /*padding: 0;*/
    animation: spin 1s linear infinite;
}

.image-container {
    display: flex;
    align-items: center;
    width: 200px;
    height: 200px;
    overflow: hidden;
    justify-content: center;
    position: relative;
}

.logo-video {
    max-height: 90px;
    position: absolute;
    left: -37px;
    top: -6px;
    cursor: pointer;

}

.nav-links {
    margin: 35px 17% 0 1%;
    text-align: right;
}

.nav-link {
    display: inline;
    font-size: 17px;
    color: #cfcfcf;
    margin-right: 10px;
    transition: color 0.4s ease-In-out;
    cursor: pointer;
    width: max-content;
}

.hamburger {
    right: 23px;
    top: 20px;
    position: absolute;
    cursor: pointer;
    z-index: 12;
    display: none;
    /*visibility: hidden;*/
}

.hamburger-line {
    width: 30px;
    border: solid 2px #b619ff;
    margin-bottom: 6px;
    background-color: red;
    border-radius: 3px;
    transition: all 0.4s ease-In-out;
    transform-origin: 1px;

}

.slide-menu {
    top: 0;
    /*width: 100%;*/
    height: 100%;
    position: fixed;
    z-index: 11;
    /* Adjust the color and alpha value for transparency */
    /*background-color: rgba(0, 4, 22, 0.8); !* White with 50% opacity *!*/
    background-color: #000000a1; /* White with 50% opacity */

    /* Apply the blur effect */
    backdrop-filter: blur(20px);

    /* Optional: for better cross-browser compatibility */
    -webkit-backdrop-filter: blur(20px);
    transition: all 0.4s ease-In-out;
    overflow-x: hidden;
    display: none;
}

.slide-links {
    position: relative;
    top: 35%;
    transition: opacity 0.4s ease-In-out;
}

.slide-link {
    margin-bottom: 10px;
    overflow: hidden;
}

.slide-link-a {
    transition: color 0.4s ease-In-out;
}


.click-here {
    color: #fff;
    transition: all 0.4s ease-In-out;
    cursor: pointer;
    width: max-content;
    text-decoration: underline;
}

.user-icon-container {
    position: relative;
    display: inline;
    font-size: 17px;
    color: #cfcfcf;
    margin-right: 10px;
    transition: all 0.4s ease-In-out;
    width: max-content;
}

.user-icon {
    position: relative;
    display: inline;
    font-size: 17px;
    color: #cfcfcf;
    margin-right: 10px;
    transition: all 0.4s ease-In-out;
    cursor: pointer;
    width: max-content;
}

.nav-link:hover, .user-icon:hover, .click-here:hover {
    color: #b619ff;
}

.slide-link-a:hover {
    cursor: pointer;
    text-decoration: none;
    color: #b619ff;
}

.heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.reddit-chart-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    /*margin-top: 40px;*/
}

.video-section {
    position: relative;
    margin-top: -80px;
}

.not-playing {
    position: relative;
}

.automate-your-life {
    font-size: 259px;
    font-weight: bold;
    width: 100%;
    position: absolute;
}

.automate {
    text-align: left;
}

.your-life {
    text-align: right;
}

.hands {
    position: absolute;
    margin-top: 200px;
    width: 100%;
}

.left-hand {
    width: 49%;
    position: absolute;
    left: 0;
    top: 15px;
}

.right-hand {
    width: 51%;
    position: absolute;
    right: 0;
    top: -15px;
}

.play-button {
    position: absolute;
    left: 50%;
    margin-top: 360px;
    /*top: 150%;*/
    transform: translate(-50%, -50%);
    width: 102px;
    height: 102px;
    background-color: #b619ff;
    border-radius: 100%;
    /*display: flex;*/
    align-items: center;
    justify-content: center;
    z-index: 9;
    color: #fff;
    animation: pulse 1.5s infinite;
    cursor: pointer;
}

.explainer-video-play-button {
    position: absolute;
    left: 50%;
    margin-top: 25%;
    /*top: 150%;*/
    transform: translate(-50%, -50%);
    width: 102px;
    height: 102px;
    background-color: #b619ff;
    border-radius: 100%;
    /*display: flex;*/
    align-items: center;
    justify-content: center;
    z-index: 9;
    color: #fff;
    animation: pulse 1.5s infinite;
    cursor: pointer;
}

.triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 16px; /* Adjust size using these */
    border-color: transparent transparent transparent #fff; /* Triangle color */
    border-radius: 3px;
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%); /* Center the triangle */
}

.banner-video-wrapper {
    width: 100%;
    height: 85vh;
    top: 25px;
    position: absolute;
}

.banner-video-wrapper video {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

/*.banner-video-wrapper {*/
/*    top: 25px;*/
/*    position: absolute;*/
/*    !*max-height: 750px;*!*/
/*    overflow: hidden;*/
/*    text-align: center;*/
/*    width: 100%;*/


/*}*/


/*.banner-video {*/
/*    min-width: 100%;*/
/*    !*margin-top: -90px;*!*/
/*    !*height: auto;*!*/
/*}*/

.title {
    margin-left: 35px;
    font-size: 35px; /* Adjust the font size as needed */
    letter-spacing: 35px;
    /*margin: 0 10px; !* Adjust spacing around the text as needed *!*/
    color: #b619ff; /* Replace with the actual color of your text */
}

.line {
    height: 2px; /* Adjust the thickness of the line as needed */
    background-color: #b619ff; /* Replace with the actual color of your lines */
    flex-grow: 1; /* This will make the lines grow to fill the container */
    box-shadow: 0 0 30px 10px #610063;

}

.switch-buttons-wrapper {
    width: 100%;
    text-align: center;
    position: absolute;
    margin-top: 800px;
    z-index: 10;
}

.switch-buttons {

}

.switch-button {
    padding: 0;
    display: inline;
    border: solid 1px #b619ff;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
    /*transition: all 0.3s ease-In-out;*/

}

/*.switch-button:hover {*/
/*    cursor: pointer;*/
/*}*/

.switch-button-left {
    margin-right: 20px;
}

.contact-form {
    margin: 40px 0;
}

.contact-form-input {
    padding-left: 46px;
    padding-right: 46px;
    margin-bottom: 25px;
    width: 100%;
    height: 58px;
    border-radius: 5px;
    background-color: #00082f;
    font-size: 15px;
    color: #cfcfcf;
    border: none;
    font-weight: 400;
}

.contact-form-text-area {
    height: 190px;
    padding-top: 21px;
    padding-left: 46px;
    padding-right: 46px;
    margin-bottom: 38px;
    width: 100%;
    border-radius: 5px;
    background-color: #00082f;
    font-size: 15px;
    color: #cfcfcf;
    border: none;
    /*font-family: var(--body-font-family);*/
    font-weight: 400;
}

.contact-form-input::placeholder, .contact-form-text-area::placeholder {
    color: #cfcfcf;
}

.contact-form-input:focus, .contact-form-text-area:focus,
.contact-form-input:active, .contact-form-text-area:active {
    outline: none; /* Removes the default outline */
    box-shadow: none; /* Removes any default box shadow */
}

.section {
    margin: auto auto 4rem;
    /*position: relative;*/
    width: 90%;
    align-items: center;
}

.enclosed {
    border-radius: 25px;
    border: solid 2px #b619ff;
    background-color: #47205f;
    /*background-color: rgba(71, 32, 95, 0.6);*/
    padding: 38px 60px 38px 88px;
    box-shadow: 0 0 50px 10px #610063, inset 0 0 90px 20px #24123a;
    min-height: 280px;

}

.enclosed-image {
    width: 97%;
}

.blurred-background {
    /*position: absolute;*/
    /*top: 100px;*/
    margin: auto auto 4rem;
    width: 90%;
    /*height: 100%;*/
    background-color: #610063;
    opacity: 0.7;
    z-index: -1;
    box-shadow: 15px 30px 40px 5px #b619ff;
}

.open {
    text-align: center;
}

.section-heading {
    font-size: 50px;
    font-weight: 800;
    line-height: 1.23;
    color: #fff;
    margin-bottom: 1.2rem;
}

.congress-party {
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    width: 100%;
    margin-top: -15px;
    margin-bottom: .8rem;
}

.section-subheading {
    font-size: 30px;
    font-weight: bold;
}

.analyze-price {
    font-size: 30px;
    font-weight: bold;
    margin-top: -1rem;
}

.section-content {
    font-size: 17px;
    line-height: 1.76;
    /*color: #cfcfcf;*/
    text-align: left;
    margin-bottom: 2rem;
    overflow-y: hidden;
}

.last-line {
    margin-bottom: 0;
}

.learn-more-wrapper {
    margin-top: 3rem;
}

.learn-more {
    padding: 23px 66px;
    border-radius: 55px;
    background-color: #b619ff;
    font-size: 15px;
    font-weight: 800;
    line-height: 1.67;
    color: #fff;
    text-transform: uppercase;
    /*align-self: flex-start;*/
}

.learn-more:hover {
    text-decoration: none;
}

.send-wrapper {
    /*margin: auto;*/
    text-align: center;
}

.send {
    padding: 23px 66px;
    border-radius: 55px;
    background-color: #b619ff;
    font-size: 15px;
    font-weight: 800;
    line-height: 1.67;
    color: #fff;
    text-transform: uppercase;
    border: none;
    width: 228px;
    /*align-self: flex-start;*/
}

.save-wrapper {
    margin-top: 1rem;
    text-align: center;
}

.save {
    border-radius: 55px;
    background-color: #b619ff;
    font-weight: 800;
    line-height: 1.67;
    color: #fff;
    text-transform: uppercase;
    border: none;
    width: 120px;
    padding: 13px 0;
    font-size: 14px;
}

.half-section {
    width: 50%;
    text-align: left;
    padding: 0 12px;
}

.home-section-1 {
    /*position: relative;*/
    margin-top: 76px;
}

.home-page-text {
    /*margin-top: 820px;*/
    top: 880px;
    position: absolute;
}

.explainer-video-wrapper {
    margin-bottom: 3.5rem;
}

.explainer-video-wrapper video {
    align-items: center;
    width: 90%;
}

.about-section {
    margin: auto auto 4rem;
    width: 90%;
}

.services-page-text {
    margin-bottom: -2rem;
}

.services-section {
    display: inline;
}

.service-section {
    display: inline-block;
    margin: 0 20px 70px;
}

.service-section-service-image {
    border: solid 2px #b619ff;
    border-radius: 28px;
    margin-bottom: 15px;
    width: 90%;
    max-width: 500px;
}

.service-section-task-name {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 35px;
}

.blogs-section {
    display: inline;
}

.blog-section {
    display: inline-block;
    margin: 0 20px 70px;
    /*width: 420px;*/
}

.blog-section-blog-image {
    border: solid 2px #b619ff;
    border-radius: 28px;
    margin-bottom: 15px;
    width: 90%;
}

.blog-section-task-name {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 35px;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.margin-bottom-half-rem {
    margin-bottom: .5rem;
}

.margin-bottom-1 {
    margin-bottom: 1rem;
}

.margin-bottom-2 {
    margin-bottom: 2rem;
}

.margin-top-half-rem {
    margin-top: .5em;
}

.margin-top-2 {
    margin-top: 2rem;
}


.small-heading {
    font-size: 100px;
    font-weight: bold;
    line-height: 1;
    margin-top: 22px;
    margin-bottom: 25px;
}

.small-subheading {
    font-size: 30px;
    font-weight: bold;
}

.bullet-point {
    margin-left: 50px;
    color: #fff;
    margin-bottom: 5px;
}

.login-form {
    margin: auto
}

.have-account {
    font-size: 17px;
    margin-top: 15px;
    margin-bottom: -10px;
}

.dashboard-section {
    margin: auto auto 4rem;
    /*position: relative;*/
    width: 90%;
    /*align-items: center;*/
}

.admin-dashboard-entries {
    width: 140px;
    margin-right: 2%;
    text-align: left;
}

.admin-dashboard-entry {
    /*width: 210px;*/
    font-size: 17px;
    height: 60px;
    padding-left: 15px;
    padding-top: 15px;
    border-radius: 10px;
    cursor: pointer;
    transition: color 0.4s ease-In-out;
}

/*.analyze-dashboard-entry {*/
/*    !*width: 210px;*!*/
/*    font-size: 17px;*/
/*    height: 60px;*/
/*    padding-left: 15px;*/
/*    padding-top: 15px;*/
/*    border-radius: 10px;*/
/*    cursor: pointer;*/
/*    transition: color 0.4s ease-In-out;*/
/*}*/

.admin-subheading {
    margin-bottom: 1rem;
}

.admin-dashboard-content {
    position: relative;
    width: 100%;
    max-width: 1200px;
    overflow: scroll;
    /*max-height: 750px;*/
    text-align: left;
    margin: auto;
}

.stats-dashboard-content {
    position: relative;
    width: 100%;
    overflow: scroll;
    max-height: 750px;
    text-align: left;
    margin: auto;
}

.reddit-dashboard-content {
    position: relative;
    width: 100%;
    max-width: 1000px;
    overflow: scroll;
    max-height: 750px;
    text-align: left;
    margin: auto;
}

.dashboard-number-ofs {
    /*text-align: left;*/
}

.dashboard-number-of {
    border: solid 2px #b619ff;
    width: 210px;
    font-size: 25px;
    height: 100px;
    /*padding-left: 15px;*/
    /*padding-top: 15px;*/
    border-radius: 10px;
    cursor: pointer;
    margin: 10px;
    display: inline-grid;
    text-align: center;
}

.dashboard-number-of-content {
}


.dashboard-table {
    width: 100%;
    /*border-collapse: separate; !* This is required for border-spacing to work *!*/
    /*border-spacing: 10px 0; !* horizontal and vertical spacing *!*/

}

.dashboard-tr {
    /*padding-bottom: 100px;*/
    border-bottom: solid 2px #b619ff;
    /*display: flex;*/
    /*justify-content: space-between;  !* This distributes space evenly between the cells *!*/
    /*width: 100%;  !* Ensures the row takes full width *!*/
}

tbody .dashboard-tr:last-child {
    border-bottom: none;
}

.dashboard-td {
    max-width: 50px; /* Adjust width as needed */
    overflow-x: auto; /* Change to auto to avoid always showing scrollbar */
    white-space: nowrap; /* Prevents text from wrapping to a new line */
    padding-right: 10px;
}

.dashboard-td-inner {
    overflow: scroll;
}

.th-width-1-percent {
    width: 1%;
}

.th-width-5-percent {
    width: 5%;
}

.th-width-10-percent {
    width: 10%;
}

.th-width-12-percent {
    width: 12%;
}

.th-width-15-percent {
    width: 15%;
}

.th-width-18-percent {
    width: 18%;
}

.th-width-19-percent {
    width: 19%;
}

.th-width-20-percent {
    width: 20%;
}

.th-width-25-percent {
    width: 25%;
}

.th-width-30-percent {
    width: 30%;
}

.th-width-40-percent {
    width: 40%;
}

.th-width-50-percent {
    width: 50%;
}

.th-width-60-percent {
    width: 60%;
}

.create-subscription-form {
}

.create-subscription-section {
    width: 40%;
    display: inline-block;
}

.must-log-in {
    margin: 1rem auto auto;
}

.create-subscription-input {
    padding-left: 46px;
    padding-right: 46px;
    margin-bottom: 25px;
    height: 58px;
    border-radius: 5px;
    background-color: #00082f;
    font-size: 15px;
    color: #cfcfcf;
    border: none;
    font-weight: 400;
    width: 40%;
    margin-right: 5%;
    display: inline-block;
    color-scheme: dark;
}

.create-subscription-input::placeholder {
    color: #cfcfcf;
}

.create-subscription-text-area {
    height: 100px;
    padding-top: 21px;
    padding-left: 46px;
    padding-right: 46px;
    margin-bottom: 38px;
    width: 85%;
    border-radius: 5px;
    background-color: #00082f;
    font-size: 15px;
    color: #cfcfcf;
    border: none;
    font-weight: 400;
}

.create-subscription-text-area::placeholder {
    color: #cfcfcf;
}

.edit-payment-input {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    /*margin-bottom: 25px;*/
    /*height: 58px;*/
    border-radius: 5px;
    background-color: #00082f;
    font-size: 15px;
    color: #cfcfcf;
    border: none;
    font-weight: 400;
    /*margin-right: 5%;*/
    display: inline-block;
    color-scheme: dark;
}

.edit-payment-input::placeholder {
    color: #cfcfcf;
}

#edit-payment-method-number {
    width: 200px;
}

#edit-payment-method-exp-month {
    width: 50px;
    margin-right: 5px;
}

#edit-payment-method-exp-year {
    width: 70px;
    margin-left: 5px;
}

#edit-payment-method-cvv {
    width: 70px;
}

/*.edit-payment-input::placeholder {*/
/*    color: #cfcfcf;*/
/*}*/

.dashboard-link {
    transition: color 0.4s ease-In-out;
}

.dashboard-link:hover {
    color: #b619ff;
    text-decoration: none;
}

.subscription-content {
    width: 100%;
    overflow: scroll;
    max-height: 750px;
    text-align: left;

}

.subscription-subheading {
    text-align: center;
    font-size: 50px;
    font-weight: bold;
}

.subscription-amount {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.subscription-infos {
    text-align: center;
}

.subscription-info {
    border-bottom: solid 2px #b619ff;
    width: 25%;
    margin: 30px;
    display: inline-grid;
    text-align: left;

}

.subscription-info-heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
}

.subscription-info-content {
    overflow-x: scroll;
    font-size: 18px;
    margin-bottom: 8px;
}

.payment-link-content {
    width: 100%;
    overflow: scroll;
    max-height: 750px;
    text-align: left;

}

.payment-link-subheading {
    text-align: center;
    font-size: 50px;
    font-weight: bold;
}

.payment-link-amount {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.payment-link-infos {
    text-align: center;
}

.payment-link-info {
    border-bottom: solid 2px #b619ff;
    width: 25%;
    margin: 30px;
    display: inline-grid;
    text-align: left;

}

.payment-link-info-heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
}

.payment-link-info-content {
    overflow-x: scroll;
    font-size: 18px;
    margin-bottom: 8px;
}

.user-content {
    width: 100%;
    overflow: scroll;
    max-height: 750px;
    text-align: left;

}

.user-subheading {
    text-align: center;
    font-size: 50px;
    font-weight: bold;
}

.user-amount {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.user-infos {
    text-align: center;
}

.user-info {
    border-bottom: solid 2px #b619ff;
    width: 25%;
    margin: 30px;
    display: inline-grid;
    text-align: left;

}

.user-info-heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
}

.user-info-content {
    overflow-x: scroll;
    font-size: 18px;
    margin-bottom: 8px;
}

.admin-user-subheading {
    font-size: 30px;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 10px;
}

.filter-add-subtract-button {
    border: outset 2px #b619ff;
    border-radius: 7px;
    background: none;
    color: white;
    text-align: center;
    height: 28px;
    width: 28px;
    padding: 0;
    font-size: 14px;
    /*margin-bottom: 3px;*/
}

.filter-add-subtract-button:focus, .filter-add-subtract-button:active {
    outline: none; /* Removes the default outline */
    box-shadow: none; /* Removes any default box shadow */
}

.watchlist-remove-button {
    border: outset 2px #b619ff;
    border-radius: 7px;
    background: none;
    color: white;
    text-align: center;
    height: 26px;
    width: 24px;
    padding: 0;
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
}

.watchlist-remove-button:focus, .watchlist-remove-button:active {
    outline: none; /* Removes the default outline */
    box-shadow: none; /* Removes any default box shadow */
}

.watchlist-remove-button-text {
    line-height: 0;
    margin-bottom: 1px;
}

.ticker-input {
    padding-bottom: 3px;
    text-transform: uppercase;
    height: 28px;
    width: 120px;
    border: solid 2px #b619ff;
    /*text-align-last: center;*/
    background: none;
    color: #fff;
    border-radius: 5px;
    margin: auto auto 5px;
    text-align: center;
    /*font-family: 'Poppins', sans-serif;*/
    /*font-size: 18px;*/
    /*letter-spacing: 3px;*/
    word-wrap: break-word;
    display: block;
}

.ticker-input::placeholder {
    text-transform: none;
}

.ticker-input:focus, .ticker-input:active {
    outline: none; /* Removes the default outline */
    box-shadow: none; /* Removes any default box shadow */
}

.politician-input {
    padding-bottom: 3px;
    height: 28px;
    width: 200px;
    border: solid 2px #b619ff;
    background: none;
    color: #fff;
    border-radius: 5px;
    margin: auto auto 5px;
    text-align: center;
    word-wrap: break-word;
    display: block;
}

.politician-input::placeholder {
    text-transform: none;
}

.politician-input:focus, .politician-input:active {
    outline: none; /* Removes the default outline */
    box-shadow: none; /* Removes any default box shadow */
}

.search {
    border: outset 2px #b619ff;
    padding: 0 5px;
    /*text-align-last: center;*/
    background: none;
    color: #fff;
    border-radius: 5px;
    margin: auto;
    text-align: center;
    /*font-family: 'Poppins', sans-serif;*/
    /*font-size: 18px;*/
    /*letter-spacing: 3px;*/
    word-wrap: break-word;
}

.ticker-input-submit-container {
    /*margin-top: .6rem;*/
    margin-bottom: 1rem;
}

.suggestions-wrapper {
    position: relative;
}

.suggestions {
    list-style-type: none; /* Remove bullet points */
    margin: -5px auto auto;
    border: solid 2px #b619ff;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    background-color: #47205f;
    padding: .2rem 0;
    left: 50%; /* Center align */
    transform: translateX(-50%); /* Center align */
    text-align: center;
    width: 120px;
    z-index: 15;
    max-height: 8rem;
    overflow: scroll;
}

.suggestions-notification {
    width: 110px;
    left: 60%;
    margin-top: 0;
}

.suggestion {
    cursor: pointer;
}

.suggestion.highlighted {
    background-color: #b619ff; /* Highlight color */
    color: #fff; /* Text color for highlighted suggestion */
}

.expand-collapse-button {
    position: absolute;
    right: 8px;
    background: none;
    color: #b619ff;
    border: none;
    font-weight: bold;
    transition: transform .5s ease-in-out;
}

.expand-collapse-button.expanded {
    transform: rotate(180deg);
}

.notification {
    border: solid 2px #b619ff;
    border-radius: 15px;
    width: 100%;
    max-width: 600px;
    margin: auto auto 10px;
    position: relative;
    padding: 8px;
    /*cursor: pointer;*/
    transition: max-height .5s ease-in-out;
    max-height: 50px; /* Initial height */
    overflow: hidden; /* Hide overflowing content */
}

.notification.expanded {
    height: max-content; /* Expanded height */
}

.notification:last-child {
    /*margin-bottom: 0;*/
}

.notification-table {
    margin: 15px auto;
}

.notification-name-container {
    text-align: center;
    margin-bottom: 8px;
}

.notification-name {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    background: none;
    text-decoration: none;
    color: #fff;
    border-radius: 5px;
    border: none;
    padding: 0;
    /*margin-bottom: 8px;*/
}

.notification-name.expanded {
    outline: solid 2px #b619ff;
}

.filter-dropdown-notification {
    -webkit-appearance: none;
    text-align: center;
    padding: 0;
    font-size: 18px;
    line-height: 20px;
    height: 25px;
    width: 140px;
    border: solid 2px #b619ff;;
    text-align-last: center;
    background: none;
    color: white;
    border-radius: 5px;
}

.filter-dropdown-notification:focus, .filter-dropdown-notification:active {
    outline: none; /* Removes the default outline */
    box-shadow: none; /* Removes any default box shadow */
}

.filter-dropdown-notification:hover {
    cursor: pointer;
}

.ticker-input-notification {
    text-transform: uppercase;
    margin: auto;
    height: 28px;
    width: 110px;
    border: solid 2px #b619ff;
    /*text-align-last: center;*/
    background: none;
    color: white;
    border-radius: 5px;
    /*margin: auto auto 5px;*/
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    /*letter-spacing: 3px;*/
    word-wrap: break-word;
    display: inline;
    padding: 0;
}

.ticker-input-notification::placeholder {
    text-transform: none;
}

.ticker-input-notification:focus, .ticker-input-notification:active {
    outline: none; /* Removes the default outline */
    box-shadow: none; /* Removes any default box shadow */
}

.notify-of {
    text-align: center;
    margin: 0 7px;
}

.notify-of-symbol-wrapper {
    margin-top: 2px;
    /*margin-bottom: 10px;*/
}

.filter-number-button-notify {
    position: absolute;
    height: 25px;
    margin-top: 15px;
    margin-left: 2px;
    /*background: white;*/
    padding: 0;
    width: 26px;
    /*border: solid grey 2px;*/
    border: outset 2px #b619ff;;
    text-align-last: center;
    background: none;
    color: white;
    border-radius: 5px;
}

.filter-number-button-notify:focus, .filter-number-button-notify:active {
    outline: none; /* Removes the default outline */
    box-shadow: none; /* Removes any default box shadow */
}

.filter-comparator-dropdown {
    padding: 0;
    width: 30px;
    margin-left: 2px;
    margin-right: 2px;
    height: 25px;
    text-align: center;
    text-align-last: center;
    /*background: white;*/
    /*color: black;*/
}

/*#filter-conditions-list {*/
/*    !*max-height: 145px;*!*/
/*    overflow: overlay;*/
/*    width: max-content;*/
/*    margin: auto auto 5px;*/
/*    flex-direction: column-reverse;*/

/*}*/

.filter-dropdown {
    -webkit-appearance: none;
    text-align: center;
    padding: 0;
    font-size: 18px;
    line-height: 20px;
    height: 25px;
    width: 140px;
    border: solid 2px #b619ff;;
    text-align-last: center;
    background: none;
    color: white;
    border-radius: 5px;
}

.filter-dropdown:focus, .filter-dropdown:active {
    outline: none; /* Removes the default outline */
    box-shadow: none; /* Removes any default box shadow */
}

.filter-dropdown:hover {
    cursor: pointer;
}

.filter-number {
    -webkit-appearance: none;
    text-align: center;
    padding: 0;
    width: 140px;
    height: 24px;
    font-size: 18px;
    line-height: 18px;
    border: solid 2px #b619ff;;
    background: none;
    color: white;
    border-radius: 5px;
    box-sizing: border-box;
}

.filter-number::placeholder {
    font-size: 18px;
    line-height: 18px;
    /*font-family: Akaya, serif;*/

}

.filter-number::-webkit-outer-spin-button,
.filter-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.filter-number:focus, .filter-number:active {
    outline: none; /* Removes the default outline */
    box-shadow: none; /* Removes any default box shadow */
}

.filter-number-button {
    position: absolute;
    height: 25px;
    margin-top: 1px;
    margin-left: 2px;
    /*background: white;*/
    padding: 0;
    width: 26px;
    /*border: solid grey 2px;*/
    border: outset 2px #b619ff;;
    text-align-last: center;
    background: none;
    color: white;
    border-radius: 5px;
}

.filter-number-button:focus, .filter-number-button:active {
    outline: none; /* Removes the default outline */
    box-shadow: none; /* Removes any default box shadow */
}

#filter-number-button-text {
    /*margin-top: -3px;*/
    font-size: 18px;
    line-height: 18px;
    font-family: Akaya, serif;
    color: white;
}

.filter-comparator-dropdown {
    padding: 0;
    width: 30px;
    margin-left: 2px;
    margin-right: 2px;
    height: 25px;
    text-align: center;
    text-align-last: center;
    /*background: white;*/
    /*color: black;*/
}

.notification-condition-buttons {
    text-align: center;
    /*margin-bottom: 5px;*/
}


.notify-active {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
}

.active-text {
    text-align: center;
    margin-left: 5px;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50px;
}

.switch:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 0;
    /*bottom: 0px;*/
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50px;
    outline: #b619ff solid 2px;
}

input:checked + .switch {
    background-color: #b619ff;
}

input:focus + .switch {
    box-shadow: 0 0 1px #b619ff;
}

input:checked + .switch:before {
    -webkit-transform: translateX(34px);
    -ms-transform: translateX(34px);
    transform: translateX(34px);
}

.notification-save {
    width: 80px;
    padding: 0;
    border: solid 2px #b619ff;
    background: none;
    color: #fff;
    border-radius: 5px;
    margin: auto auto 5px;
    text-align: center;
    word-wrap: break-word;
}

.success-or-error {
    position: absolute;
    /*margin-top: -1.5rem;*/
    top: -.3rem;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadeIn .5s;
    transition: opacity 1s ease-in-out;
    /*z-index: 9999;*/
    text-align: center;
    width: 100%;
    line-height: 1rem;
    /*opacity: 0;*/
}

.success-or-error-analyze {
    position: absolute;
    /*margin-top: -1.5rem;*/
    top: -13rem;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadeIn .5s;
    transition: opacity 1s ease-in-out;
    /*z-index: 9999;*/
    text-align: center;
    width: 100%;
    line-height: 1rem;
    /*opacity: 0;*/
}

.success-or-error-notifications {
    position: absolute;
    top: -.6rem;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadeIn .5s;
    transition: opacity 1s ease-in-out;
    text-align: center;
    width: 100%;
    line-height: 1rem;
}

.success-or-error-congress {
    position: absolute;
    top: -9rem;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadeIn .5s;
    transition: opacity 1s ease-in-out;
    text-align: center;
    width: 100%;
    line-height: 1rem;
}

.success-or-error-login {
    position: absolute;
    top: -4.5rem;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadeIn .5s;
    transition: opacity 1s ease-in-out;
    text-align: center;
    width: 100%;
    line-height: 1rem;
}

.indicators-table {
    margin: 1rem auto auto;
    max-width: 600px;
}

.indicators-tr {
    border-bottom: solid 2px #b619ff;
    text-align: left;
    /*font-size: 20px;*/
}

tbody .indicators-tr:last-child {
    border-bottom: none;
}

.indicators-td {
    overflow-x: auto; /* Change to auto to avoid always showing scrollbar */
    white-space: nowrap; /* Prevents text from wrapping to a new line */
}

.indicator-left {
    /*padding-left: 35%;*/
    width: 100%
}

.chart-change-percentage {
    text-align: center;
    margin-top: .5rem;
}

.chart-switch-buttons {
    text-align: center;
    margin-top: .5rem;
}

.chart-switch-button {
    color: #fff;
    background: none;
    border-radius: 5px;
    border: none;
    width: 50px;
    transition: color 0.4s ease-In-out;
}

.chart-switch-button:hover {
    color: #b619ff;
}

.chart-indicator-buttons {
    text-align: center;
    margin-top: 1rem;
}

.chart-indicator-button {
    color: #fff;
    background: none;
    border-radius: 5px;
    border: none;
    width: 100px;
    transition: color 0.4s ease-In-out;
    margin: 5px;
}

.reddit-comments {
    /*margin: 1rem auto auto;*/
}

.comment-subreddit-name {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    background: none;
    text-decoration: none;
    color: #fff;
    border-radius: 5px;
    border: none;
    margin-bottom: 8px;
    cursor: pointer;
}

.reddit-comment-row {
    border-bottom: solid 2px #b619ff;
}


.reddit-comment-row:last-child {
    border-bottom: none;
}


.reddit-comment {
    /*font-size: 14px;*/
    padding: 10px;
    border: solid #b619ff 2px;
    border-radius: 10px;
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: .5rem;
    margin-top: .5rem;
    box-shadow: inset 0 0 40px 1px #3a1358;

}

.reddit-comment-author {
    /*font-size: 12px;*/
    /*border-bottom: solid #b619ff 1px;*/
    padding: 10px 10px 0;
    width: max-content;
    /*margin-top: 10px;*/
    /*min-width: 150px;*/
}

.reddit-comment-date {
    /*font-size: 14px;*/
    padding: 5px;
    /*width: max-content;*/
    margin-top: 1rem;
    margin-bottom: 5px;
}

.reddit-comment-link-wrapper {
    /*width: max-content;*/
}

.reddit-comment-link {
    margin-bottom: 1rem;
    margin-top: 5px;
    padding-left: 10px;
}

.comments {
    border: solid 2px #b619ff;
    border-radius: 15px;
    width: 100%;
    max-width: 800px;
    margin: 1rem auto 10px;
    position: relative;
    padding: 8px;
    /*cursor: pointer;*/
    transition: max-height .5s ease-in-out;
    max-height: 50px; /* Initial height */
    overflow: hidden; /* Hide overflowing content */
}

.comments.expanded {
    height: max-content; /* Expanded height */
    overflow: scroll;
}

.comments:last-child {
    /*margin-bottom: 0;*/
}

.reddit-dashboard-entries {
    width: 100%;
    /*width: 170px;*/
    /*margin-right: 2%;*/
    /*text-align: left;*/
}

.reddit-dashboard-entry {
    /*width: 210px;*/
    width: 25%;
    max-width: 180px;
    display: inline-block;
    font-size: 17px;
    height: 60px;
    /*padding-left: 15px;*/
    /*padding-top: 15px;*/
    border-radius: 10px;
    cursor: pointer;
    transition: color 0.4s ease-In-out;
    align-content: center;
    /*margin: 0 5px;*/
}

.analyze-dashboard-entry {
    /*width: 210px;*/
    width: 25%;
    max-width: 180px;
    display: inline-block;
    font-size: 17px;
    height: 60px;
    /*padding-left: 15px;*/
    /*padding-top: 15px;*/
    border-radius: 10px;
    cursor: pointer;
    transition: color 0.4s ease-In-out;
    align-content: center;
    /*margin: 0 5px;*/
}

.nothing-here {
    margin-top: 1rem;
    text-align: center;
}

.conditions-symbols {
    border: solid 2px #b619ff;
    border-radius: 15px;
    width: 100%;
    max-width: 600px;
    margin: 1rem auto auto;
    position: relative;
    padding: 1rem;
    /*cursor: pointer;*/
    transition: max-height .5s ease-in-out;
    text-align: center;
    overflow: scroll;
}

.conditions-symbol {
    display: inline-block;
    width: 20%;
    text-decoration: none;
    font-size: 18px;
    text-align: left;
    padding-left: 1rem;
}

.filter-table {
    margin: auto;

}

.user-account-links {
    text-align: center;
    margin-top: 3rem;
}

.user-account-link {
    transition: color 0.4s ease-In-out;
    display: block;
    cursor: pointer;
    margin: 5px auto auto;
    width: max-content;
}

.user-account-link:hover {
    color: #b619ff;
    text-decoration: none;
}

.reddit-switch-buttons {
    text-align: center;
    margin: .5rem auto auto;
    width: 100%;
}

.reddit-switch-button {
    color: #fff;
    background: none;
    border-radius: 5px;
    border: none;
    width: 50px;
    transition: color 0.4s ease-In-out;
    display: inline-block;
    cursor: pointer;
}

.reddit-switch-button:hover {
    color: #b619ff;
}

.reddit-mentions-chart-container {
    max-width: 800px;
    margin: auto;
}

.error {
    margin-top: 100px;
    padding: 10px;
}

.chart-container {
    height: 435px;
}

.agree-checkbox-wrapper {
    margin: 0 auto 1rem;
}

.agree-checkbox {
    margin-right: 10px;
    cursor: pointer;
}

.max-height-600-400 {
    max-height: 600px;
}

.error-loading {
    margin: 1rem auto auto;
}

.terms {
    padding: 0 1rem;
}

.terms-heading {
    text-align: left;
    font-weight: bold;
    font-size: 26px;
}
.terms-paragraph {
    text-align: left;
}

.privacy-table {
    margin-bottom: 1rem;
}

.privacy-th {
    border: solid 2px #fff
}

.privacy-td {
    text-align: left;
    border: solid 2px #fff;
    padding: 10px;
}

.privacy-li {
    margin-left: -30px;
}

.watchlist-symbol {
    display: inline-table;
}